import React from "react";

export const MeetingCard = ({
  selectedState,
  setState,
  cardTitle,
  cardDesc,
  meetings,
  activated,
}) => {
  return (
    <div className="card p-4">
      <h6>{cardTitle}</h6>
      <h6 className="text-muted">{cardDesc}</h6>
      {meetings.map((meeting) => {
        const { id, title, productName, soldOut } = meeting;
        const isChecked = selectedState.includes(id);

        return (
          <div className="form-check" key={id}>
            <input
              className="form-check-input"
              type="checkbox"
              id={id}
              checked={isChecked}
              onChange={(e) => {
                if (e.target.checked) {
                  setState([...selectedState, id]);
                } else {
                  setState(selectedState.filter((selected) => selected !== id));
                }
              }}
              disabled={!activated || soldOut}
            />
            <label
              className={`form-check-label ${
                soldOut && "text-decoration-line-through"
              }`}
              htmlFor={id}
            >
              <small>{productName}</small>
              <em>{soldOut ? " (SOLD OUT)" : ""}</em>
            </label>
          </div>
        );
      })}
    </div>
  );
};
