import axios from "axios";
import React, { useState } from "react";
import { ProductCard } from "./ProductCard";
import { MeetingCard } from "./MeetingCard";

const ProductSelection = ({
  userId,
  token,
  url,
  purchasedPhysical,
  purchasedCongressDinner,
  congressDinner,
  gaSelected,
  physical,
  gaTypes,
  sst,
}) => {
  const [selected, setSelected] = useState(null);
  const [selectedCongressDinner, setSelectedCongressDinner] = useState(null);

  const [selectedGa, setSelectedGa] = useState([]);

  const disableSubmit =
    // selected === null && selectedCongressDinner === null
    (!purchasedPhysical && selected === null && selectedGa.length === 0) ||
    (purchasedPhysical &&
      selectedCongressDinner === null &&
      selectedGa.length === 0);

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[selected, selectedCongressDinner]}
        />

        <input
          type="hidden"
          name="product_meeting_ids"
          value={selectedGa.join(",")}
        />

        {/* ------------ GA Meeting CARD ------------ */}
        {!gaSelected && (
          <>
            <MeetingCard
              cardTitle={"50th General Assembly 12-16 July 2025"}
              cardDesc={"(you can choose more than 1)"}
              meetings={gaTypes}
              selectedState={selectedGa}
              setState={setSelectedGa}
              activated={true}
            />
          </>
        )}

        <ProductCard
          cardTitle={"53rd WCC 14-19 July 2025"}
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated={!purchasedPhysical}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null);
              setSelectedCongressDinner(null);
            }
          }}
          handleRadio={() => {
            // setSelectedCongressDinner(null);
          }}
        />

        {(selected || purchasedPhysical) && (
          <>
            <h4 className="text-center text-navy mt-4">
              Select Congress Dinner
            </h4>
            {/* ------------ congress dinner CARD ------------ */}
            <ProductCard
              cardTitle={"Congress Dinner Ticket"}
              products={congressDinner}
              selectedState={selectedCongressDinner}
              setSelectedState={setSelectedCongressDinner}
              activated={!purchasedCongressDinner}
              handleCheckbox={(e) => {
                if (!e.target.checked) {
                  setSelectedCongressDinner(null);
                }
              }}
              handleRadio={() => {
                // setSelectedCongressDinner(null);
              }}
            />
          </>
        )}

        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur 8% SST
          </strong>
        )}
        {/* for international delegate is compulsory to select one of the precongress */}
        <button
          type="submit"
          className="btn text-white btn-primary d-block mx-auto mt-4"
          disabled={disableSubmit}
        >
          Purchase Pass
        </button>
      </form>
    </div>
  );
};

export default ProductSelection;
